import React from 'react'
import UniversalLink from 'components/UniversalLink'
import PropTypes from 'prop-types'

export default function GetStartedMessage ({ docsTitle }) {
  return (
    <p style={{ fontSize: '1.2rem' }}>
      <strong>Before you get into {docsTitle}</strong>, make sure you check out
      our{' '}
      <UniversalLink to="/docs/api/getting-started/">
        getting started guide
      </UniversalLink>
      .
    </p>
  )
}

GetStartedMessage.propTypes = {
  docsTitle: PropTypes.string.isRequired,
}
